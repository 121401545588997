import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Close } from 'app/components/Common/Close'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Wave } from 'app/components/Icons'
import useLockedBody from 'app/utils/useLockedBody'
import { uniq } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import Masonry from 'react-masonry-component'

import { Gallery } from './Gallery'

export interface Props {
  images: ImageProps[]
  thumbnails?: ImageProps[]
  title?: string
}

export const MosaicGallery = memo(function MosaicGallery({
  images,
  thumbnails,
  title,
}: Props) {
  if (images?.length < 1) {
    return null
  }

  const [_locked, setLocked] = useLockedBody()
  const [modalGalleryStatus, setModalGalleryStatus] = useState(false)
  const [clickedSlide, setClickedSlide] = useState<any>(null)

  let counter = 0

  useEffect(() => {
    if (modalGalleryStatus) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 27:
            setLocked(false)
            setModalGalleryStatus(false)
            setClickedSlide(0)
            break
        }
      })
    }
  }, [modalGalleryStatus])

  return (
    <Container>
      <FadeInUp>
        <Head>
          <Wave />

          {title ? <Title>{title}</Title> : null}
        </Head>
      </FadeInUp>

      <Mosaic
        options={{
          gutter: 63,
          percentPosition: true,
          transitionDuration: 0,
        }}
      >
        {uniq(thumbnails)
          .slice(0, 8)
          .map((item, index) => {
            counter = counter + 1

            return (
              <Item
                data-counter={counter}
                key={index}
                onClick={(e) => {
                  if (!modalGalleryStatus) {
                    setLocked(true)
                    setModalGalleryStatus(true)
                  } else {
                    setLocked(false)
                    setModalGalleryStatus(false)
                  }

                  const realClickedSlide =
                    Number(e.currentTarget.getAttribute('data-counter')) - 1

                  setClickedSlide(realClickedSlide)
                  setTimeout(() => {
                    setClickedSlide(null)
                  }, 100)
                }}
              >
                <LazyLoadComponent>
                  <Image {...item} />
                </LazyLoadComponent>
              </Item>
            )
          })}
      </Mosaic>

      <Modal className={modalGalleryStatus ? 'open' : undefined}>
        <Close
          onClick={() => {
            setLocked(false)
            setModalGalleryStatus(false)
            setClickedSlide(0)
          }}
          variant="invert"
        />
        <Gallery
          clickedSlide={clickedSlide}
          images={images}
          isOpen={modalGalleryStatus}
        />
      </Modal>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  text-align: center;
`

const Head = styled.div`
  margin-bottom: -8.125rem;

  > svg {
    width: 22.125rem;
    height: 3.875rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight5};
    margin-bottom: 2rem;
  }

  @media (max-width: 1199px) {
    margin-bottom: 1.25rem;

    > svg {
      width: 6.25rem;
      height: 1.875rem;
    }
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 7.5rem;
  line-height: 10.125rem;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
`

const Mosaic = styled(Masonry)<MasonryProps>`
  margin-right: -3.9375rem;

  @media (max-width: 1199px) {
    display: flex;
    flex-wrap: wrap;
    height: auto !important;
    margin-right: -0.75rem;
  }
`

const Item = styled.div`
  width: calc(25% - 3.9375rem);
  height: 25.7vh;
  cursor: pointer;
  overflow: hidden;
  margin-top: 3.9375rem;
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
  &:nth-of-type(1),
  &:nth-of-type(3),
  &:nth-of-type(5),
  &:nth-of-type(6) {
    height: 45.6vh;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 1199px) {
    width: calc(50% - 0.75rem);
    height: 12.5rem;
    margin: 0.75rem 0.75rem 0 0;
    position: relative !important;
    top: auto !important;
    left: auto !important;
    &:nth-of-type(1),
    &:nth-of-type(3),
    &:nth-of-type(5),
    &:nth-of-type(6) {
      height: 12.5rem;
    }
  }
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`

interface MasonryProps {
  children?: React.ReactNode | React.ReactNode[]
}
